import React from "react"

const DemoBanner = () => (
  <>
    <div id="demobanner">
      <span>Please note: this is a demo site only - no orders will be accepted, or fulfilled from this site.</span>
    </div>
  </>
);

export default DemoBanner